<template>
  <!--解决分页等组件，是英文的问题-->
  <el-config-provider :locale="locale">
    <router-view/>
  </el-config-provider>
</template>
<script>
import zhCn from 'element-plus/lib/locale/lang/zh-cn'

export default {
  data() {
    return {
      locale: zhCn
    }
  },
  created() {
    /**
     * 解决当页面刷新，store中信息消失问题
     * @author YangLei
     * @createTime 2022-2-28 17:14
     **/
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      // 存储状态
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
    }
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      console.log('beforeunload')
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })

    //禁止H5界面放大与缩小功能
     window.onload = function() {
      document.addEventListener('touchstart', function(event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      })
      document.addEventListener('gesturestart', function(event) {
        event.preventDefault()
      })
    } 

  }
}
</script>