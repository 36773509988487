import variableApi from "@/api/system/variable";
import packageJson from "../../package.json";
import notifyUtil from "@/utils/notifyUtil";

export default {
    // 检查UI版本并自动更新
    checkAndReload(){
        let packageVersion = packageJson.version;
        variableApi.queryVarNumVariable({varNum: "version"}).then(response => {
            if(response.data.code == 1) {
                let serverVersion = response.data.data.version;
                if (packageVersion != serverVersion) {
                    notifyUtil.showWarnMsg('系统更新，请稍后...')
                    setTimeout(() => {
                        location.reload() // 直接刷新
                    }, 1500)
                }
            }
        })
    }
}