
import { Button, Swipe, SwipeItem, Divider, Cell, CellGroup, Card, NavBar, Icon, Tab, Tabs, Popup,
    Form, Field, Badge, PullRefresh, List} from 'vant';

export function vant(app) {
    app.use(Button);
    app.use(Swipe);
    app.use(SwipeItem);
    app.use(Divider);
    app.use(Card);
    app.use(Cell);
    app.use(CellGroup);
    app.use(NavBar);
    app.use(Icon);
    app.use(Tab);
    app.use(Tabs);
    app.use(Popup);
    app.use(Form);
    app.use(Field);
    app.use(Badge);
    app.use(List);
    app.use(PullRefresh);
}
