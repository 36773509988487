import store from '@/store'

export default {
    // 判断是否有权限或路径
    hasMenu(menu) {
        let menus = store.state.userMenus;
        for (let i = 0; i < menus.length; i++) {
            if (menus[i].path == menu) {
                return true;
            }
        }
        return false;
    }
}
