import vuex from 'vuex'
// 状态管理，用于存放全局变量
export default new vuex.Store({
    state: {
        unitInfo: {},// 机构信息
        user: {},// 登录用户
        token:'',// api调用token
        userMenus:[],// 用户拥有的菜单，不是树形
    },
    mutations: {
        // 更新api调用token
        setToken(state, token) {
            state.token = token;
        },
        // 登录时设置用户信息
        setUser(state, data) {
            state.user = data;
        },
        // 用户菜单
        setUserMenus(state, data) {
            state.userMenus = data;
        },
        // 机构信息
        setUnitInfo(state, data) {
            state.unitInfo = data;
        }
    },
    actions: {}
})