import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    // 前台首页
    {
        path: '/',
        name: 'index',
        // meta: {title: '桂林墓园门户网站'},
        component: () =>
            import ('../views/front/index'),
    },
    // 二级页
    {
        path: '/channel',
        name: 'channel',
        // meta: {title: '桂林墓园门户网站'},
        component: () =>
            import ('../views/front/channel'),
    },
    // 三级页
    {
        path: '/content',
        name: 'content',
        // meta: {title: '桂林墓园门户网站'},
        component: () =>
            import ('../views/front/content'),
    },
    {
        path: '/admin/login',
        name: '后台登录',
        component: () =>
            import ('../views/admin/AdminLogin.vue'),
    },
    {
        path: '/admin/index',
        name: '后台首页',
        meta: {requiresAuth: true},
        component: () =>
            import ('../views/admin/AdminIndex.vue'),
        children: [
            /*首页*/
            {
                path: '/admin/welcome',
                name: '首页',
                meta: {requiresAuth: true},
                component: () =>
                    import ('../views/admin/Welcome')
            },
           /*信息管理*/
           {
            path: '/info/contentManage',
            name: 'contentManage',
            meta: {requiresAuth: true},
            component: () =>
                import ('../views/info/ContentManage')
            },
            {
                path: '/info/channelManage',
                name: 'channelManage',
                meta: {requiresAuth: true},
                component: () =>
                    import ('../views/info/ChannelManage')
            },
            //轮播图管理
            {
                path: '/info/rotationManage',
                name: 'rotationManage',
                meta: {requiresAuth: true},
                component: () =>
                    import ('../views/info/RotationManage')
            },
            /*友情链接*/
            {
                path: '/info/friendlinkManage',
                name: 'FriendlinkManage',
                meta: {requiresAuth: true},
                component: () =>
                    import ('../views/info/FriendlinkManage')
            },
            /*留言信息管理*/
            {
                path: '/info/feedbackManage',
                name: 'FeedbackManage',
                meta: {requiresAuth: true},
                component: () =>
                    import ('../views/info/FeedbackManage')
            },
            /*系统管理*/
            {
                path: '/system/usermanage',
                name: '用户管理',
                meta: {requiresAuth: true},
                component: () =>
                    import ('../views/admin/system/UserManage')
            },
            {
                path: '/system/deptManage',
                name: '部门管理',
                meta: {requiresAuth: true},
                component: () =>
                    import ('../views/admin/system/DeptManage')
            },
            {
                path: '/system/menuManage',
                name: '菜单管理',
                meta: {requiresAuth: true},
                component: () =>
                    import ('../views/admin/system/MenuManage')
            },
            {
                path: '/system/roleManage',
                name: '角色管理',
                meta: {requiresAuth: true},
                component: () =>
                    import ('../views/admin/system/RoleManage')
            },
            {
                path: '/system/operationLogManage',
                name: '系统日志',
                meta: {requiresAuth: true},
                component: () =>
                    import ('../views/admin/system/OperationLogManage')
            },
           
            // 数据维护
            {
                path: '/system/dictionaryManage',
                name: '数据字典表',
                meta: {requiresAuth: true},
                component: () =>
                    import ('../views/admin/system/DictionaryManage')
            },
            //系统变量
            {
                path: '/system/variableManage',
                name: 'Variable',
                meta: {requiresAuth: true},
                component: () =>
                    import ('../views/admin/system/VariableManage')
            },
            /*系统工具*/
            {
                path: '/tools/codeGenerator',
                name: '代码生成',
                meta: {requiresAuth: true},
                component: () =>
                    import ('../views/admin/tools/CodeGenerator')
            },
        ]
    },
    //h5 端
    {
        path: '/h5/index',
        name: 'h5首页',
        // meta: {title: '桂林墓园门户网站'},
        component: () =>
            import ('../views/h5/Index'),
    },
    {
        path: '/h5/channel',
        name: 'h5栏目',
        // meta: {title: '桂林墓园门户网站'},
        component: () =>
            import ('../views/h5/Channel'),
    },
    {
        path: '/h5/content',
        name: 'h5内容',
        // meta: {title: '桂林墓园门户网站'},
        component: () =>
            import ('../views/h5/Content'),
    },
    {
        path: '/h5/feedback',
        name: 'h5留言',
        // meta: {title: '桂林墓园门户网站'},
        component: () =>
            import ('../views/h5/Feedback'),
    }

]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router