import axios from 'axios';
import config from "@/api/config";
import {ElMessage,ElLoading} from "element-plus";
// import cookie from "@/utils/cookie";
import router from "@/router";
import mock from './mock';
import store from '../store'

const service = axios.create({
    baseURL: config.server, // api接口前缀
    timeout: config.useMock?100:60000, // 请求超时时间，20s未响应则停止请求（当时mock模拟数据时，直接超时，返回mock数据）
    withCredentials: true, // 允许携带cookie
})
var loading;// 加载中控件
var hideLoading;// 配置是否隐藏加载中控件
var url;// 当前请求的url
// 请求拦截器
service.interceptors.request.use(
    config => {
        hideLoading=config.hideLoading;
        // 不传递默认开启loading
        if (!hideLoading) {
            // 请求是是否开启loading
            loading = ElLoading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.3)',
            })
        }
        // 每次请求之前判断vuex中是否存在token
        // 如果存在，统一在http请求的header都加上token，后台根据token判断登录情况
        // 即使本地存在token，也可能token是过期的
        let token='';
        if (store.state.token) {
            token = store.state.token;
        }
        // let token=cookie.getCookie("token");// 每次请求都从cookie中获取一次
        console.log("请求token",token)
        config.headers.token = token;// 设置请求带token
        //有些接口是 config.headers.Authorization = token
        url=config.url;// 记录当前请求的url
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        if (loading!=undefined) {
            loading.close()
        }
        if(config.useMock){
            return Promise.resolve(
                mock.mockData(url)
            );
        }
        // 如果返回的状态码为200，可以正常拿到数据
        if (response.status === 200) {
            // token失效
            if(response.data.code=='tokenInvalid'){
                ElMessage({
                    type: 'warning',
                    message: '登录过期，请重新登录',
                    grouping: true,// 避免提示一堆
                    showClose:true,
                });
                // 登录过期，请重新登录
                router.replace({
                    path: '/admin/login',
                    query: {
                        redirect: router.currentRoute.fullPath
                    }
                });
                return Promise.reject(response);
            }
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (!hideLoading) {
            loading.close()
        }
        if(config.useMock){
            return Promise.resolve(
                mock.mockData(url)
            );
        }
        if (error.response&&error.response.status) {
            switch (error.response.status) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 401:
                    /*router.replace({
                        path: '/login',
                        query: {
                            redirect: router.currentRoute.fullPath
                        }
                    });*/
                    break;

                // 403 token过期
                case 403:
                    ElMessage({
                        type: 'warning',
                        message: '登录过期，请重新登录',
                        grouping: true,// 避免提示一堆,
                        showClose:true,
                    });
                    // 清除token
                    /*store.dispatch('FedLogOut').then(() => {
                        router.replace({
                            path: '/login',
                            query: {
                                redirect: router.currentRoute.fullPath
                            }
                        })
                    })*/
                    break;

                // 404请求不存在
                case 404:
                    ElMessage({
                        type: 'warning',
                        message: '网络请求不存在',
                        grouping: true,// 避免提示一堆
                        showClose:true,
                    });
                    break;
                default:
                    console.log(error.response.data)
                    ElMessage({
                        type: 'warning',
                        message: '抱歉，系统发生错误',
                        showClose:true,
                    });
            }
            return Promise.reject(error.response);
        } else {
            // 处理断网的情况
            // store.commit('changeNetwork', false);
            if(config.useMock){
                return Promise.resolve(
                    mock.mockData(url)
                );
            }else{
                ElMessage({
                    type: 'error',
                    message: '与服务连接断开',
                    grouping: true,// 避免提示一堆
                    showClose:true,
                });
            }
        }
    });

//导出实例
export default service;