import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
/*element-plus*/
import ElementPlus, {ElMessage} from 'element-plus'
import 'element-plus/dist/index.css'
/*自定义css*/
import '@/assets/css/global.css'
/*cookie工具类*/
import cookie from "@/utils/cookie";
// require('./api/mock.js');
import './api/mock'
import store from './store'
import Print from 'vue3-print-nb'
import menuUtil from '@/utils/menuUtil'
import variableApi from "@/api/system/variable";
import checkVersionUtil from "./utils/checkVersionUtil";


//vant ui
import { vant } from '@/vantui/vant.js'
import checkUtil from "@/utils/checkUtil";
const updateUrl=['/admin/login','/h5/index']
// 路由过滤器，当token无时，退出登录
router.beforeEach((to, from, next) => {
    // 判断地址是否有效，可能不存在于路由中
    if (to.name == undefined) {
        /*ElMessage({
            type: 'warning',
            message: '地址无效，请配置路由',
        });*/
        router.replace({
            path: '/admin/login'
        });
        return;
    }
    // 首页判断，跳转到手机端
    if(to.path=='/'&&checkUtil.isMobileBrowser()){
        router.replace({
            path: '/h5/index',
        });
    }
    // 版本检测
    if(updateUrl.indexOf(to.path)!=-1){
        checkVersionUtil.checkAndReload();
    }
    if (to.meta.requiresAuth) {
        if (cookie.getCookie("token") != '' && cookie.getCookie("user") != ''&&cookie.getCookie("token") == store.state.token) {
            next();
        } else {
            ElMessage({
                type: 'warning',
                message: '登录过期，请重新登录',
                grouping: true,
                showClose: true,
            });
            // 登录过期，请重新登录
            router.replace({
                path: '/admin/login',
                query: {
                    redirect: router.currentRoute.fullPath
                }
            });
        }
    } else {
        console.log('next')
        next();
    }
    // 修改页面标题 路由发生变化修改页面title
    if (to.meta.title) {
        document.title = to.meta.title
    } else {
        // 获取系统变量中的名称
        variableApi.queryVarNumVariable({varNum: 'sysName'}).then(response=>{
            if(response.data.code == 1) {
                document.title = response.data.data.sysName;
            }
        });

    }
});

const app = createApp(App);

app.config.globalProperties.$menuUtil = menuUtil;// 注册权限校验全局方法

vant(app);

app.use(router)
    .use(ElementPlus)
    .use(store)
    .use(Print)
    .mount('#app');
