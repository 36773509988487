import {ElNotification} from 'element-plus'

export default {
    showWarnMsg(msg) {
        ElNotification({
            title: msg,
            type: "warning",
            offset: 230,
            duration: 1500,
            showClose: false,
        });
    },
    showInfoMsg(msg) {
        ElNotification({
            title: msg,
            type: "info",
            offset: 230,
            duration: 1500,
            showClose: false,
        });
    },
    showErrorMsg(msg) {
        ElNotification({
            title: msg,
            type: "error",
            offset: 230,
            duration: 1500,
            showClose: false,
        });
    },
    showSuccessMsg(msg) {
        ElNotification({
            title: msg,
            type: "success",
            offset: 230,
            duration: 1500,
            showClose: false,
        });
    }
}