/**
 * Variable模块接口列表
 */
import request from '@/api/myaxios'; // 导入http中创建的axios实例

const variable = {
    // 根据系统编码查询
    queryVarNumVariable(data) {
        return request({
            url: '/api/system/variable/queryVarNumVariable',
            method: 'post',
            data: data,
            hideLoading:true,
        });
    },
    // Variable查询
    queryVariable(data) {
        return request({
            url: '/api/system/variable/queryVariable',
            method: 'post',
            data: data
        });
    },
    // 删除Variable
    delVariable(id) {
        return request({
            url: '/api/system/variable/delVariable/'+id,
            method: 'get'
        });
    },
    // 新增Variable
    addVariable(data) {
        return request({
            url: '/api/system/variable/addVariable',
            method: 'post',
            data: data
        });
    },
    // 编辑Variable
    editVariable(data) {
        return request({
            url: '/api/system/variable/editVariable',
            method: 'post',
            data: data
        });
    },
}

export default variable;