import Mock from "mockjs";

var data = {
    '/api/login': Mock.mock({
        "status": 200,
        "data":
        {
            "code": 1,
            "data": {
                "user": {
                    "id": '@natural(1,20)',
                    "username": '@word(3,10)',
                    "password": '@word(32)',
                    "name": '@cname',
                    "createTime": '@datetime',
                },
                "token": '@string(32)',
                "userDept": {
                    "id": '@natural(1,20)',
                    "deptName": '@cword(2,5)',
                    "deptNo": '@integer(10)',
                    "address": '@cword(20)',
                    "parentId": '@natural(1,20)',
                    "seq": '@natural(1,20)',
                    "status": '@integer(0,1)',
                    "createTime": '@datetime',
                    "isDelete": '@integer(0,1)',
                },
            }
        }
    }),
    '/api/user/queryUser': Mock.mock({
        "status": 200,
        "data":
        {
            "code": 1,
            "data": {
                "records|20": [{
                    "id|+1": '@natural(1,20)',
                    "username|+1": '@word(3,10)',
                    "password|+1": '@word(32)',
                    "name|+1": '@cname',
                    "createTime|+1": '@datetime',
                    "status|+1": '@integer(0,1)',
                    "lastLoginTime|+1": '@datetime',
                    "role|1": ['管理员', '普通用户'],
                    "deptName|+1": '@cword(2,5)',
                }],
                "total": '@natural(1,20)'
            }
        }
    }),
    '/api/role/queryRole': Mock.mock({
        "status": 200,
        "data":
        {
            "code": 1,
            "data": {
                "records|20": [{
                    "id|+1": '@natural(1,20)',
                    "seq|+1":1,
                    "status|+1": '@integer(0,1)',
                    "roleName|1": ['管理员', '普通用户']
                }],
                "total": '@natural(1,20)'
            }
        }
    }),
    // 树，可能会出现死循环，parentid不能跟id相同
    '/api/dept/queryDept': Mock.mock({
        "status": 200,
        "data":
        {
            "code": 1,
            "data|20": [{
                "id|+1": '@natural(1,20)',
                "deptName|+1": '@cword(2,5)',
                "deptNo|+1": '@integer(10)',
                "address|+1": '@cword(20)',
                "parentId|+1": '',
                "seq|+1": 1,
                "status|+1": '@integer(0,1)',
                "createTime|+1": '@datetime',
                "isDelete|+1": '@integer(0,1)',
            }]
        }
    }),
}

const mock = {
    mockData(url) {
        console.log('mock data url is', url);
        return data[url];
    }
}
export default mock;